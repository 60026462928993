<template>
  <span class="relevancy-badge" :class="badgeClass">
    <img :src="iconSrc" :alt="iconAlt" class="relevancy-icon" />
    <span class="relevancy-score">{{ score }}% Relevancy score</span>
  </span>
</template>

<script>
export default {
  name: 'RelevancyBadge',
  props: {
    score: {
      type: Number,
      required: true,
      default: 0
    }
  },
  computed: {
    badgeClass() {
      if (this.score >= 60) return 'high-relevancy';
      if (this.score >= 20) return 'medium-relevancy';
      if (this.score >= 10) return 'low-relevancy';
      return 'very-low-relevancy';
    },
    iconSrc() {
      if (this.score >= 80) return 'https://cdn.builder.io/api/v1/image/assets/TEMP/13dc803a1c906760f09cb2a9ce2f0c0f397e52756595eae4ba40f908515df8d4?placeholderIfAbsent=true&apiKey=b7b3d02748134357b1767f32084c4983';
      if (this.score >= 60) return 'https://cdn.builder.io/api/v1/image/assets/TEMP/aa978fc43e7cbb102ab8c5af3080a52bfc4856bbaf48e07cb8c2f1800762e022?placeholderIfAbsent=true&apiKey=b7b3d02748134357b1767f32084c4983';
      if (this.score >= 30) return 'https://cdn.builder.io/api/v1/image/assets/TEMP/c6000ea459d7ffdf0e996aeb3acc2329abb57ebc22718ea46ae570d63ce818b7?placeholderIfAbsent=true&apiKey=b7b3d02748134357b1767f32084c4983';
      return 'https://cdn.builder.io/api/v1/image/assets/TEMP/6717ded36114cb1f782fddb21186889b3f1fa831d04d2cec2a47e08a2ed58150?placeholderIfAbsent=true&apiKey=b7b3d02748134357b1767f32084c4983';
    },
    iconAlt() {
      if (this.score >= 80) return 'High relevancy';
      if (this.score >= 60) return 'Medium relevancy';
      if (this.score >= 30) return 'Low relevancy';
      return 'Very low relevancy';
    }
  },
  mounted() {
    if (this.score > 95){
      this.score = 95;
    }
  }
}
</script>

<style scoped>
.relevancy-badge {
  border-radius: 4px;
  display: inline-flex;
  align-items: center;
  gap: 4px;
  overflow: hidden;
  font-weight: 500;
  line-height: 1;
  padding: 2px 8px;
}

.relevancy-icon {
  width: 18px;
  height: 18px;
}

.high-relevancy {
  color: #52c41a;
}

.medium-relevancy {
  color: #faad14;
}

.low-relevancy {
  color: #faad14;
}

.very-low-relevancy {
  color: #ff4d4f;
}
</style>